import React from 'react';
import { Container } from '@axmit/clp-library';
import Typography from 'antd/es/typography';
import SEO from 'components/Seo';
import StickyBtnBlock from 'components/StickyBtnBlock';
import { redirectHelper } from 'helpers/redirect.helper';
import { graphql } from 'gatsby';
import { getEdgeTranslates, getTranslates, IEdge } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';
import { FaqQuestionsList } from 'components/FaqQuestionsList';

export default ({ data }) => {
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <div className="landing-wrapper faq-landing-wrapper">
      <SEO title={getTranslates(data.allStrapiClpFaqPage, 'header', language)} />

      <Container>
        <div className="mt-50 hidden_mob" />

        {data.allStrapiClpFaqSections?.edges?.map((section: IEdge) => (
          <React.Fragment key={section.node.id}>
            <Typography.Title
              className={`clp-typography-title clp-typography-title_size_md mb-30 ${section?.node?.order === '1' ? '' : 'mt-50'}`}
            >
              {getEdgeTranslates(section, 'header', language)}
            </Typography.Title>

            <FaqQuestionsList data={(section.node.clp_faq_questions as unknown) as Record<string, string>[]} />
          </React.Fragment>
        ))}
      </Container>
      <br />
      <StickyBtnBlock onClick={redirectHelper.redirectToNewGift} />
    </div>
  );
};

export const query = graphql`
  query {
    allStrapiClpFaqSections(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          header__en
          header__fr
          order
          clp_faq_questions {
            id
            header__en
            header__fr
            content__en
            content__fr
            order
          }
        }
      }
    }
    allStrapiClpFaqPage {
      edges {
        node {
          header__en
          header__fr
          par1__header__en
          par1__header__fr
          par1__content__en
          par1__content__fr
          par2__header__en
          par2__header__fr
          par2__content__1__en
          par2__content__1__fr
          par2__content__2__en
          par2__content__2__fr
          par2__content__3__en
          par2__content__3__fr
          par2__content__4__en
          par2__content__4__fr
          par3__header__en
          par3__header__fr
          par3__content__en
          par3__content__fr
          par4__header__en
          par4__header__fr
          par4__content__en
          par4__content__fr
          par5__header__en
          par6__header__en
          par7__header__en
          par8__header__en
          par9__header__en
          par10__header__en
          par11__header__en
          par5__header__fr
          par6__header__fr
          par7__header__fr
          par8__header__fr
          par9__header__fr
          par10__header__fr
          par11__header__fr
          par5__content__en
          par6__content__en
          par7__content__en
          par8__content__en
          par9__content__en
          par10__content__en
          par11__content__en
          par5__content__fr
          par6__content__fr
          par7__content__fr
          par8__content__fr
          par9__content__fr
          par10__content__fr
          par11__content__fr
        }
      }
    }
  }
`;
