import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  data: Record<string, string>[];
}

export const FaqQuestionsList = (props: IComponentProps) => {
  const { data } = props;
  const {
    i18n: { language }
  } = useTranslation();

  if (!data.length) {
    return null;
  }

  const sortedData = data.sort((a, b) => Number(a.order) - Number(b.order));

  return (
    <>
      {sortedData.map(item => (
        <React.Fragment key={item.id}>
          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md mt-30">
            <b>{item[`header__${language}`]}</b>
          </Typography.Paragraph>

          <Typography.Paragraph className="clp-typography-text clp-typography-text_size_md t_with-line-breaks content-card mb-25">
            {item[`content__${language}`]}
          </Typography.Paragraph>
        </React.Fragment>
      ))}
    </>
  );
};
